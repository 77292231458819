import type { AppProps } from 'next/app'
import { useContext, useEffect } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from '../src/styles/theme'
import { appWithTranslation } from 'next-i18next'
import '../src/styles/index.css'

import { ConfCtx, withConf } from '../src/context/conf'
import { isCustomer } from '../src/customer.interface'

declare global {
  interface Window {
    botpressWebChat: any
    recombee: any
  }
}
const cust = process.env.customer
if (!isCustomer(cust)) { throw new Error('unsupported customer') }

const App = ({ Component, pageProps }: AppProps) => {
  const confSt = useContext(ConfCtx)

  useEffect(() => {
    Array.from(document.querySelectorAll('img'))
      .forEach(img => {
        if (!img.hasAttribute('alt')) {
          img.setAttribute('alt', '')
        }
      })
  }, [])

  return (
    <ChakraProvider theme={theme(confSt.retailer)}>
      <Component {...pageProps} />
    </ChakraProvider>
  )
}
export default withConf(appWithTranslation(App))
