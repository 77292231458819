import { Customer } from '../../customer.interface'
import LogoStraighttalk from '/public/assets/straighttalkLogo_header.png'
import TracfoneLogoHeader from '/public/assets/tracfoneLogo_header.png'
import Arrow from '/public/assets/arrow_straighttalk.svg'

export const themes = (retailer: Customer) => {
  switch (retailer) {
    case 'tbv':
      return {
        header: '#FFFFFF',
        headerHelmet: { bg: '#FFFFFF', text: '#000000', mobileBg: '#EE0000', mobileText: '#FFFFFF' },
        footer: '#FFFFFF',
        footerIcon: '#000000',
        search: '#000000',
        text: { header: '#000000', footer: '#000000' },
        title: '#000000',
        badge: { bg: '#F0F0F0', text: '#000000', icon: '#000000' },
        bot: { bg: '#D52B1E', color: '#FFFFFF' },
        contact: { bg: '#F0F0F0', icon: '#000000', text: '#000000' },
        logo: 'totalLogo.png',
        categoryFaq: { bg: '#FFFFFF', border: '#000000', text: '#000000' },
        primary: '#000000',
        favicon: 'tbv.svg',
        headerStyles: {
          mainHeaderBlock: {
            p: 2,
          },
          menuIcon: {
            filter: 'invert(1)',
          },
        },
        footerStyles: {
          stackDirection: 'row',
        },
        shopNowStyles: { bg: '#D52B1E', color: '#FFFFFF' },
      }
    case 'wfm':
      return {
        header: '#397BBD',
        headerHelmet: { bg: '#397BBD', text: '#FFFFFF' },
        footer: '#397BBD',
        footerIcon: '#397BBD',
        text: { header: '#FFFFFF', footer: '#FFFFFF' },
        title: '#000000',
        badge: { bg: '#397BBD', text: '#FFFFFF', icon: '#F1BC4F' },
        search: '#397BBD',
        bot: { bg: '#397BBD', color: '#F1BC4F' },
        contact: { bg: '#397BBD', icon: '#F1BC4F', text: '#FFFFFF' },
        logo: 'walmartLogo.png',
        categoryFaq: { bg: '#FFFFFF', border: '#397BBD', text: '#397BBD' },
        primary: '#397BBD',
        favicon: 'wfm.ico',
        footerStyles: {
          stackDirection: 'row',
        },
      }
    case 'gosmart':
      return {
        header: '#000000',
        headerHelmet: { bg: '#000000', text: '#FFFFFF' },
        footer: '#000000',
        footerIcon: '#C7D75B',
        search: '#C7D75B',
        text: { header: '#FFFFFF', footer: '#FFFFFF' },
        title: '#000000',
        badge: { bg: '#000000', text: '#FFFFFF', icon: '#C7D75B' },
        bot: { bg: '#C7D75B', color: '#000000' },
        contact: { bg: '#C7D75B', icon: '#000000', text: '#000000' },
        logo: 'goSmartLogo.png',
        categoryFaq: { bg: '#C7D75B', border: '#C7D75B', text: '#000000' },
        primary: '#C7D75B',
        favicon: 'go.ico',
        footerStyles: {
          stackDirection: 'row',
        },
      }
    case 'net10':
      return {
        header: '#FFFFFF',
        headerHelmet: { bg: '#FFFFFF', text: '#000000' },
        footer: '#FFFFFF',
        footerIcon: '#53ABE9',
        search: '#53ABE9',
        text: { header: '#000000', footer: '#000000' },
        title: '#000000',
        badge: { bg: '#53ABE9', text: '#FFFFFF', icon: '#000000' },
        bot: { bg: '#53ABE9', color: '#000000' },
        contact: { bg: '#53ABE9', icon: '#000000', text: '#FFFFFF' },
        logo: 'net10Logo.png',
        categoryFaq: { bg: '#53ABE9', border: '#53ABE9', text: '#000000' },
        primary: '#53ABE9',
        favicon: 'nt.ico',
        headerStyles: {
          menuIcon: {
            filter: 'invert(1)',
          },
          logoStyles: {
            width: 91,
            height: 50,
          },
          logoStylesMobile: {
            width: 73,
            height: 40,
          },
        },
        footerStyles: {
          stackDirection: 'row',
        },
      }
    case 'pageplus':
      return {
        header: '#FFFFFF',
        headerHelmet: { bg: '#D33033', text: '#FFFFFF' },
        footer: '#FFFFFF',
        footerIcon: '#D33033',
        search: '#D33033',
        text: { header: '#000000', footer: '#000000' },
        title: '#000000',
        badge: { bg: '#626469', text: '#FFFFFF', icon: '#FFFFFF' },
        bot: { bg: '#D33033', color: '#000000' },
        contact: { bg: '#D33033', icon: '#000000', text: '#FFFFFF' },
        logo: 'pagePlusLogo.png',
        categoryFaq: { bg: '#D33033', border: '#D33033', text: '#FFFFFF' },
        primary: '#D33033',
        favicon: 'pp.ico',
        headerStyles: {
          menuIcon: {
            filter: 'invert(1)',
          },
        },
        footerStyles: {
          stackDirection: 'row',
        },
      }
    case 'simplemobile':
      return {
        header: '#A3D240',
        headerHelmet: { bg: '#221F20', text: '#FFFFFF' },
        footer: '#A3D240',
        footerIcon: '#A3D240',
        search: '#A3D240',
        text: { header: '#000000', footer: '#000000' },
        title: '#000000',
        badge: { bg: '#A3D240', text: '#000000', icon: '#FFFFFF' },
        bot: { bg: '#A3D240', color: '#000000' },
        contact: { bg: '#A3D240', icon: '#FFFFFF', text: '#000000' },
        logo: 'simpleMobileLogo.png',
        categoryFaq: { bg: '#FFFFFF', border: '#A3D240', text: '#A3D240' },
        primary: '#A3D240',
        favicon: 'sm.ico',
        menuIcon: {
          filter: 'invert(1)',
        },
        footerStyles: {
          stackDirection: 'row',
        },
      }
    case 'straighttalk':
      return {
        header: '#bee81e',
        shopNowStyles: { bg: '#bee81e', text: '#000000' },
        headerHelmet: { bg: '#bee81e', text: '#000000' },
        footer: '#231f20',
        footerIcon: '#C7E64E',
        search: '#C7E64E',
        text: { header: '#000000', footer: '#FFFFFF' },
        title: '#000000',
        badge: { bg: '#C7E64E', text: '#000000', icon: '#FFFFFF' },
        bot: { bg: '#C7E64E', color: '#000000' },
        contact: { bg: '#C7E64E', icon: '#FFFFFF', text: '#000000' },
        logo: 'straighttalkLogo.png',
        categoryFaq: { bg: '#FFFFFF', border: '#C7E64E', text: '#C7E64E' },
        primary: '#C7E64E',
        favicon: 'st.ico',
        headerStyles: {
          separatedLogo: {
            src: LogoStraighttalk,
          },
          helmet: {
            container: {
              justify: 'space-between ',
              py: 4,
              mb: 3,
              px: 4,
            },
            languageStyle: {
              fontSize: 10,
              textTransform: 'uppercase',
            },
            zipStyle: {
              fontSize: 10,
            },
            linkStyle: {},
          },
          menuIcon: {
            filter: 'invert(1)',
          },
          accordionContainer: {
            px: 0,
          },
          mainHeaderBlock: {
            width: '100%',
            p: 0,
          },
          headerAccordion: {
            buttonContainer: {
              justify: 'space-between',
            },
            accordionItem: {
              position: 'inherit',
            },
            accordionButton: {
              _hover: {
                background: 'auto',
                textDecoration: 'auto',
              },
              _expanded: {
                div: {
                  div: {
                    w: '100%',
                  },
                },
                bgColor: '#ccff00',
                border: 0,
              },
              py: 1,
              px: 0,
            },
            flexContainer: {
              _hover: {
                div: {
                  w: '100%',
                },
              },
            },
            text: {
              fontSize: ['xs', 'xs', 'xs', 'xs', 'sm', 'sm'],
              px: 5,
              _after: {
                transform: 'scale(0, 1)',
                transition: 'transform 250ms ease-in-out 0s',
              },
              w: 'max-content',
              textTransform: 'uppercase',
            },
            box: {
              transition: 'width .5s',
              position: 'absolute',
              h: 0.5,
              borderTopRadius: 4,
              mt: 10,
              w: '0%',
              backgroundColor: 'black',
            },
            accordionPanel: {
              boxShadow: '0 6px 12px #0000002d',
              w: 'auto',
              left: -148,
              top: 46,
            },
          },
        },
        footerStyles: {
          signUpInput: {
            backgroundColor: 'black.400',
            borderRadius: '0',
            borderTop: '0',
            borderLeft: '0',
            placeholder: 'Email',
            _focusVisible: {
              border: 0,
              boxShadow: 0,
            },
            _focus: {
              border: 0,
              boxShadow: 0,
            },
          },
          signUpButton: {
            styles: {
              backgroundColor: 'text.footer',
              borderRadius: 0,
              p: 0,
              _focus: {
                boxShadow: 0,
              },
              _focusVisible: {
                border: 0,
                boxShadow: 0,
                borderRadius: 0,
              },
            },
            button: {
              image: {
                src: Arrow,
              },
              text: '',
            },
          },
          signUpAgree: {
            exclusionsText: '',
            styes: {
              w: ['auto', 'auto', 'auto', 'sm'],
              filter: 'opacity(0.5)',
            },
          },
          signUpThanksStyle: {
            mt: 5,
          },
          stackDirection: 'column',
        },
      }
    case 'tracfone':
      return {
        header: '#FFFFFF',
        headerHelmet: { bg: '#FFFFFF', text: '#000f9f' },
        footer: '#04109e',
        footerIcon: '#FFFFFF',
        search: '#012979',
        text: { header: '#04109e', footer: '#FFFFFF' },
        title: '#000000',
        badge: { bg: '#012979', text: '#FFFFFF', icon: '#FFFFFF' },
        bot: { bg: '#012979', color: '#FFFFFF' },
        contact: { bg: '#012979', icon: '#FFFFFF', text: '#FFFFFF' },
        logo: 'tracfoneLogo.png',
        categoryFaq: { bg: '#FFFFFF', border: '#012979', text: '#012979' },
        primary: '#012979',
        favicon: 'tf.ico',
        headerStyles: {
          helmet: {
            container: {
              px: 0,
            },
            zipStyle: {
              filter:
                'invert(12%) sepia(98%) saturate(4777%) hue-rotate(238deg) brightness(66%) contrast(123%)',
            },
            linkStyle: {},
          },
          iconFilter: {
            filter:
              'invert(12%) sepia(98%) saturate(4777%) hue-rotate(238deg) brightness(66%) contrast(123%)',
          },
          menuIcon: {
            filter: 'invert(1)',
          },
          accordionContainer: {
            px: 0,
          },
          mainHeaderBlock: {
            width: '100%',
            p: 0,
          },
          headerMobile: {
            accordionButton: {
              py: 4,
              fontSize: 'xl',
              textTransform: 'none',
              _expanded: {
                bg: 'headerHelmet.text',
                color: 'headerHelmet.bg',
              },
            },
            containerLink: {
              borderBottom: 0,
              textTransform: 'none',
              fontSize: 'xl',
            },
            containerZip: {
              fontSize: 'sm',
              bgColor: '#f5f9fc',
              textTransform: 'none',
            },
          },
          shopList: {
            mainContainer: {
              w: ['auto', 'auto', 'auto', '3xl', '5xl', '6xl'],
              flexDir: [
                'column-reverse',
                'column-reverse',
                'column-reverse',
                'row',
              ],
              wrap: 'wrap',
            },
            heading: {
              mb: 5,
              fontSize: 'xs',
              as: 'h3',
              color: 'black.400',
              w: ['80%', '80%', '100%'],
              textTransform: 'uppercase',
            },
            unorderedList: {
              listItem: {
                p: 3,
                color: 'black.400',
                fontSize: 13,
              },
            },
            downContainer: {
              alignItems: 'stretch',
              flexDirection: 'row',
              gap: 5,
            },
            downHeading: {
              fontSize: 26,
              mr: 16,
              mt: 16,
              color: 'text.header',
              fontWeight: 'semibold',
            },
            divider: {
              left: 'auto',
              top: 0,
              orientation: 'horizontal',
              height: 0,
            },
            link: {
              mt: 16,
            },
            shopListContainer: {
              gap: 10,
              justifyContent: [
                'center',
                'center',
                'center',
                'center',
                'flex-start',
              ],
              flexDir: ['column', 'column', 'column', 'row', 'row'],
              color: 'text.header',
              borderRadius: 5,
              border: '1px solid',
              alignItems: 'center',
              bgColor: '#f5f9fc',
              display: 'flex',
              margin: 0,
              px: [1, 1, 1, 1, 10],
              py: [5, 16, 16, 5, 5],
              h: '100%',
              w: [24, 24, 28, 'auto', 255],
            },
            navText: {
              fontWeight: 'semibold',
              justifyContent: [
                'center',
                'center',
                'center',
                'center',
                'flex-start',
              ],
              cursor: 'pointer',
              _hover: { color: 'gray.600' },
              ml: [0, 0, 0, 0, 6],
              fontSize: 'xs',
              display: 'flex',
              p: 0,
              py: [7, 0, 0, 0, 0],
              color: 'text.header',
              textAlign: ['center', 'center', 'center', 'center', 'left'],
            },
          },
          navLink: {
            fontSize: 'md',
            w: 'max-content',
            fontWeight: 'bold',
            cursor: 'pointer',
            _hover: { color: 'none', textDecoration: 'underline' },
          },
          headerAccordion: {
            accordionItem: {
              position: 'inherit',
            },
            accordionButton: {
              _expanded: {
                border: 0,
                w: 'max-content',
                fontSize: ['md', 'md', 'md', 'md', 'xl', 'xl'],
                fontWeight: 'bold',
                color: 'header',
                cursor: 'pointer',
                position: 'relative',
                py: 4,
                borderTopRadius: 5,
                backgroundColor: 'text.header',
                px: 6,
              },
              w: 'max-content',
              fontSize: ['md', 'md', 'md', 'md', 'xl', 'xl'],
              fontWeight: 'bold',
              color: 'text.header',
              cursor: 'pointer',
              position: 'relative',
              py: 4,
              borderTopRadius: 5,
              px: 6,
              _hover: {
                backgroundColor: '#e3e9ef',
              },
            },
            accordionPanel: {
              boxShadow: '0 6px 12px #0000002d',
              border: '1px solid',
              w: 'auto',
              backgroundColor: 'white',
              left: 0,
              top: 61,
            },
            flex: {
              alignItems: 'flex-start',
              flexDirection: 'row',
              p: 50,
              w: ['auto', 'auto', 'xl', 'xl', '4xl', '5xl'],
            },
            heading: {
              color: 'text.header',
              fontSize: 34,
              pb: 3,
              mb: 3,
              mr: 16,
              maxW: 64,
              textAlign: 'start',
            },
            flexChildren: {
              flexDirection: ['column', 'column', 'column', 'column', 'row'],
              alignItems: 'flex-start',
              flexWrap: 'wrap',
            },
          },
          menuList: {
            border: 0,
          },
          logoWhite: {
            src: TracfoneLogoHeader,
          },
        },
        footerStyles: {
          signUpInput: {
            py: 6,
            color: 'black',
            backgroundColor: 'text.footer',
            placeholder: 'Email address (required)',
            mr: 5,
          },
          signUpButton: {
            styles: {
              w: 44,
              p: 6,
              background: 'none',
              fontSize: 'sm',
              border: '1px solid',
              _focus: {
                boxShadow: 0,
              },
              _focusVisible: {
                border: 0,
                boxShadow: 0,
                borderRadius: 0,
              },
            },
            button: {
              text: 'ENTER',
            },
          },
          signUpAgree: {
            exclusionsText:
              '*Exclusions apply. Plan Purchase required. Max discount $100.',
            styes: {
              w: ['auto', 'auto', 'auto', 'md'],
            },
          },
          signUpThanksStyle: {},
          stackDirection: 'column',
        },
      }
    case 'safelink':
      return {
        header: '#FFFFFF',
        headerHelmet: { bg: '#F0F0F0', text: '#000000' },
        footer: '#F0F0F0',
        footerIcon: '#FDBB30',
        search: '#FDBB30',
        text: { header: '#000000', footer: '#000000' },
        title: '#000000',
        badge: { bg: '#FDBB30', text: '#000000', icon: '#FFFFFF' },
        bot: { bg: '#FDBB30', color: '#FFFFFF' },
        contact: { bg: '#F0F0F0', icon: '#FDBB30', text: '#000000' },
        logo: 'safeLinkLogo.png',
        categoryFaq: { bg: '#FFFFFF', border: '#FDBB30', text: '#FDBB30' },
        primary: '#FDBB30',
        favicon: 'sf.ico',
        headerStyles: {
          menuIcon: {
            filter: 'invert(1)',
          },
        },
        footerStyles: {
          stackDirection: 'row',
        },
      }
    case 'tfunbranded':
      return {
        header: '#FFFFFF',
        headerHelmet: { bg: '#FFFFFF', text: '#000000' },
        footer: '#FFFFFF',
        footerIcon: '#000000',
        search: '#000000',
        text: { header: '#000000', footer: '#000000' },
        title: '#000000',
        badge: { bg: '#F0F0F0', text: '#000000', icon: '#000000' },
        bot: { bg: '#D52B1E', color: '#FFFFFF' },
        contact: { bg: '#F0F0F0', icon: '#000000', text: '#000000' },
        logo: '',
        categoryFaq: { bg: '#FFFFFF', border: '#000000', text: '#000000' },
        primary: '#000000',
        favicon: '',
        headerStyles: {
          menuIcon: {
            filter: 'invert(1)',
          },
        },
        footerStyles: {
          stackDirection: 'row',
        },
      }
    default:
      return {
        header: '#FFFFFF',
        headerHelmet: { bg: '#FFFFFF', text: '#000000' },
        footer: '#FFFFFF',
        footerIcon: '#000000',
        search: '#000000',
        text: { header: '#000000', footer: '#000000' },
        title: '#000000',
        badge: { bg: '#F0F0F0', text: '#000000', icon: '#000000' },
        bot: { bg: '#D52B1E', color: '#FFFFFF' },
        contact: { bg: '#F0F0F0', icon: '#000000', text: '#000000' },
        logo: 'totalLogo.png',
        categoryFaq: { bg: '#FFFFFF', border: '#000000', text: '#000000' },
        primary: '#000000',
        favicon: 'tbv.svg',
        footerStyles: {
          stackDirection: 'row',
        },
      }
  }
}
