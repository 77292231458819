import { Brand, Customer, CustomerConfig } from './src/customer.interface'

export const brands: Record<Brand, CustomerConfig> = {
  tbv: {
    loginLink: {
      en: 'https://www.totalbyverizon.com/#customerlogin',
      es: 'https://www.totalbyverizon.com/es/#customerlogin',
    },
    headerFooterUrl: 'https://assets-dev-kms-assets.s3.amazonaws.com/tbv',
    headerExternalLinkBase: 'https://www.totalbyverizon.com',
    contentCustomerId: 'totalbyverizon',
    customerName: 'Total By Verizon',
    gtmId: 'G-8MPRWFVCRJ',
    gtmIdTracfone: 'GTM-N8P7GT',
    retailer: 'tbv',
    specialHeaderAndFooter: true,
    commonTopicsProductId: '25209',
    phoneSupport: '1-866-663-3633',
    footerConfig: {
      support: true,
      footerAdditionalLinks: {
        privacy_policy: {
          linkText: {
            en: 'Privacy Policy',
            es: 'Política de Privacidad',
          },
          linkUrl: {
            en: 'https://www.tracfonewirelessinc.com/en/Privacy%2BPolicy/',
            es: 'https://www.tracfonewirelessinc.com/es/Privacy%2BPolicy/',
          },
        },
        privacy_central: {
          linkText: {
            en: 'Privacy Central',
            es: 'Central de Privacidad',
          },
          linkUrl: {
            en: 'https://www.totalbyverizon.com/ccpa/home',
            es: 'https://www.totalbyverizon.com/ccpa/home',
          },
        },
        privacy_choices: {
          linkText: {
            en: 'Your Privacy Choices',
            es: 'Sus Opciones de Privacidad',
          },
          linkUrl: {
            en: 'https://www.totalbyverizon.com/ccpa/home?reqType=dns',
            es: 'https://www.totalbyverizon.com/ccpa/home?reqType=dns',
          },
          linkIcon: 'https://oag.ca.gov/sites/all/files/agweb/images/privacy/privacyoptions.svg',
        },
      },
    },
    contactSection: true,
    chatBotLink: 'https://website-production-bot-assets.s3.amazonaws.com/script.js',
    connectWithUsSection: true,
    popularFaqsPrefix: 'TW',
    popularFaqsCustomerId: 'totalwireless',
    shopNowDevices: [
      {
        name: 'iPhone 11',
        redirectUrl: 'https://www.totalbyverizon.com/smartphones/apple-iphone-11-64gb-prepaid',
      },
      {
        name: 'iPhone 13 - 5G Capable',
        redirectUrl: 'https://www.totalbyverizon.com/smartphones/apple-iphone-13-128gb-prepaid',
      },
      {
        name: 'iPhone SE (2020)',
        redirectUrl: 'https://www.totalbyverizon.com/smartphones/apple-iphone-se2-64gb-prepaid',
      },
      {
        name: 'S536DL',
        redirectUrl: 'https://www.totalbyverizon.com/smartphones/samsung-galaxy-a53-5g-prepaid',
      },
      {
        name: 'iPhone 13 Pro (with 5G)',
        redirectUrl: 'https://www.totalbyverizon.com/smartphones/apple-iphone-13-pro-128gb-prepaid',
      },
      {
        name: 'iPhone 13 Pro Max - 5G Capable',
        redirectUrl: 'https://www.totalbyverizon.com/smartphones/apple-iphone-13-pro-max-128gb-prepaid',
      },
      {
        name: 'iPhone 12 mini - 5G',
        redirectUrl: 'https://www.totalbyverizon.com/smartphones/apple-iphone-12-mini-64gb-prepaid',
      },
      {
        name: 'S136DL',
        redirectUrl: 'https://www.totalbyverizon.com/smartphones/samsung-galaxy-a13-5g-prepaid',
      },
      {
        name: 'G990U1/G990U3',
        redirectUrl: 'https://www.totalbyverizon.com/smartphones/samsung-galaxy-s21-fe-5g-prepaid',
      },
      {
        name: 'iPhone 12 (5G Capable)',
        redirectUrl: 'https://www.totalbyverizon.com/smartphones/apple-iphone-12-64gb-prepaid',
      },
    ],
    isShowHiddenTutorial: false,
    recombee: {
      dev: {
        name: 'devrefactory-tbv-dev',
        publicToken: 'M3z3DJ31HlzPQHn6WCxLAEwUQs1GcaCB2MEcBzOI0c3zXdTmBU0Im8FNAzYCKtIW',
      },
      prod: {
        name: 'devrefactory-dev',
        publicToken: 'fABM9SJZeqlydC0sItE6YtWHb5gaHVNkZttXeg69UhJRzTvCOPbyKZYAkb7AF4Ep',
      },
    },
  },
  wfm: {
    loginLink: {
      en: 'https://www.myfamilymobile.com/accountrecovery',
    },
    headerFooterUrl: 'https://assets-dev-kms-assets.s3.amazonaws.com',
    headerExternalLinkBase: '',
    contentCustomerId: 'wfm',
    customerName: 'Walmart FamilyMobile',
    gtmId: 'G-1FEGDZTT8B',
    gtmIdTracfone: 'GTM-5924QSG',
    retailer: 'wfm',
    commonTopicsProductId: '24914',
    phoneSupport: '1-877-440-9758',
    contactSection: true,
    chatBotLink: 'https://website-production-bot-assets.s3.amazonaws.com/wfm/script.js',
    connectWithUsSection: true,
    popularFaqsPrefix: 'WFM',
    popularFaqsCustomerId: 'wfm',
    isShowHiddenTutorial: false,
    specialHeaderAndFooter: false,
    footerConfig: {
      footerAdditionalLinks: {
        privacy_policy: {
          linkText: {
            en: 'Privacy Policy',
            es: 'Política de Privacidad',
          },
          linkUrl: {
            en: 'https://www.tracfonewirelessinc.com/en/Privacy%2BPolicy/',
            es: 'https://www.tracfonewirelessinc.com/es/Privacy%2BPolicy/',
          },
        },
        privacy_central: {
          linkText: {
            en: 'Privacy Central',
            es: 'Central de Privacidad',
          },
          linkUrl: {
            en: 'https://www.myfamilymobile.com/ccpa/home',
            es: 'https://www.myfamilymobile.com/ccpa/home',
          },
        },
        privacy_choices: {
          linkText: {
            en: 'Your Privacy Choices',
            es: 'Sus Opciones de Privacidad',
          },
          linkUrl: {
            en: 'https://www.myfamilymobile.com/ccpa/home?reqType=dns',
            es: 'https://www.myfamilymobile.com/ccpa/home?reqType=dns',
          },
          linkIcon: 'https://oag.ca.gov/sites/all/files/agweb/images/privacy/privacyoptions.svg',
        },
      },
    },
    recombee: {
      dev: {
        name: 'devrefactory-wfm-dev',
        publicToken: 'Uoue4JuvCVrvNi7mN7VNuoO8THWHWvdTvrKJ8Qi0hpMeZESMX8ZbktPkhVH0k7qv',
      },
      prod: {
        name: 'devrefactory-wfm',
        publicToken: 'DHzNPyoEsuLttgjXUpbN4rD5Ne2BEw9h2ExqgK7s7Sw5s0ymMBgpCaxgwJGOVPUx',
      },
    },
  },
  gosmart: {
    loginLink: {
      en: 'https://www.gosmartmobile.com/wps/portal/home/h/account/login/',
    },
    headerFooterUrl: 'https://assets-dev-kms-assets.s3.amazonaws.com',
    headerExternalLinkBase: '',
    contentCustomerId: 'gosmart',
    customerName: 'Go Smart',
    gtmId: 'G-0GQ96YCK39',
    gtmIdTracfone: 'GTM-WNSL855',
    retailer: 'gosmart',
    commonTopicsProductId: '24947',
    phoneSupport: '1-877-582-7788',
    contactSection: true,
    connectWithUsSection: true,
    chatBotLink: 'https://website-production-bot-assets.s3.amazonaws.com/gosmart/script.js',
    popularFaqsPrefix: 'GS',
    popularFaqsCustomerId: 'gosmart',
    isShowHiddenTutorial: false,
    specialHeaderAndFooter: false,
    footerConfig: {
      footerAdditionalLinks: {
        privacy_policy: {
          linkText: {
            en: 'Privacy Policy',
            es: 'Política de Privacidad',
          },
          linkUrl: {
            en: 'https://www.tracfonewirelessinc.com/en/Privacy%2BPolicy/',
            es: 'https://www.tracfonewirelessinc.com/es/Privacy%2BPolicy/',
          },
        },
        privacy_central: {
          linkText: {
            en: 'Privacy Central',
            es: 'Central de Privacidad',
          },
          linkUrl: {
            en: 'https://www.gosmartmobile.com/ccpa/home',
            es: 'https://www.gosmartmobile.com/ccpa/home',
          },
        },
        privacy_choices: {
          linkText: {
            en: 'Your Privacy Choices',
            es: 'Sus Opciones de Privacidad',
          },
          linkUrl: {
            en: 'https://www.gosmartmobile.com/ccpa/home?reqType=dns',
            es: 'https://www.gosmartmobile.com/ccpa/home?reqType=dns',
          },
          linkIcon: 'https://oag.ca.gov/sites/all/files/agweb/images/privacy/privacyoptions.svg',
        },
      },
    },
    recombee: {
      dev: {
        name: 'devrefactory-gosmart-dev',
        publicToken: 'MZt7oA8d3NOMlq6tKUMNZfeLgex57sBJMkX6dtuszorGIx93rKvIhuGQKesZj5bd',
      },
      prod: {
        name: 'devrefactory-gosmart',
        publicToken: 'T0aUbPFwbeoapnEB7hbPqv8sZGkZXhXlnGz6JXvy87HGAWfEmcm71njMj14BUmIh',
      },
    },
  },
  net10: {
    loginLink: {
      en: 'https://www.net10wireless.com/myaccount/login/collectusername',
    },
    headerFooterUrl: 'https://assets-dev-kms-assets.s3.amazonaws.com',
    headerExternalLinkBase: '',
    contentCustomerId: 'net10wireless',
    customerName: 'Net 10',
    gtmId: 'G-YPKMZMFJM6',
    gtmIdTracfone: 'GTM-TSLW3Z',
    retailer: 'net10',
    commonTopicsProductId: '24891',
    phoneSupport: '1-866-663-3633',
    contactSection: false,
    connectWithUsSection: true,
    chatBotLink: 'https://website-production-bot-assets.s3.amazonaws.com/net10/script.js',
    popularFaqsPrefix: 'NT',
    popularFaqsCustomerId: 'net10wireless',
    isShowHiddenTutorial: false,
    specialHeaderAndFooter: false,
    footerConfig: {
      footerAdditionalLinks: {
        privacy_policy: {
          linkText: {
            en: 'Privacy Policy',
            es: 'Política de Privacidad',
          },
          linkUrl: {
            en: 'https://www.tracfonewirelessinc.com/en/Privacy%2BPolicy/',
            es: 'https://www.tracfonewirelessinc.com/es/Privacy%2BPolicy/',
          },
        },
        privacy_central: {
          linkText: {
            en: 'Privacy Central',
            es: 'Central de Privacidad',
          },
          linkUrl: {
            en: 'https://www.net10wireless.com/ccpa/home',
            es: 'https://www.net10wireless.com/ccpa/home',
          },
        },
        privacy_choices: {
          linkText: {
            en: 'Your Privacy Choices',
            es: 'Sus Opciones de Privacidad',
          },
          linkUrl: {
            en: 'https://www.net10wireless.com/ccpa/home?reqType=dns',
            es: 'https://www.net10wireless.com/ccpa/home?reqType=dns',
          },
          linkIcon: 'https://oag.ca.gov/sites/all/files/agweb/images/privacy/privacyoptions.svg',
        },
      },
    },
    recombee: {
      dev: {
        name: 'devrefactory-net10wireless-dev',
        publicToken: 'iF2jYhEhqV4jtae8Z37I2gWf2A1im79oP3oLaM1HCbpXig4dwC3dqtjIriydVzQb',
      },
      prod: {
        name: 'devrefactory-net10wireless',
        publicToken: 'gnAGuVUwg0RMQ9irziDOCMc2SZtvL8RbpVFMJphkGT2iaOAHe6akie3ne7YIO38e',
      },
    },
  },
  pageplus: {
    loginLink: {
      en: 'https://www.pagepluscellular.com/login',
    },
    headerFooterUrl: 'https://assets-dev-kms-assets.s3.amazonaws.com',
    headerExternalLinkBase: '',
    contentCustomerId: 'pageplus',
    customerName: 'Pageplus',
    gtmId: 'G-JQ6H8MXPLX',
    gtmIdTracfone: 'GTM-525JFM',
    retailer: 'pageplus',
    commonTopicsProductId: '24933',
    phoneSupport: '(800) 550-2436',
    contactSection: true,
    connectWithUsSection: true,
    chatBotLink: 'https://website-production-bot-assets.s3.amazonaws.com/pageplus/script.js',
    popularFaqsPrefix: 'PP',
    popularFaqsCustomerId: 'pageplus',
    isShowHiddenTutorial: false,
    specialHeaderAndFooter: false,
    footerConfig: {
      footerAdditionalLinks: {
        privacy_policy: {
          linkText: {
            en: 'Privacy Policy',
            es: 'Política de Privacidad',
          },
          linkUrl: {
            en: 'https://www.tracfonewirelessinc.com/en/Privacy%2BPolicy/',
            es: 'https://www.tracfonewirelessinc.com/es/Privacy%2BPolicy/',
          },
        },
        privacy_central: {
          linkText: {
            en: 'Privacy Central',
            es: 'Central de Privacidad',
          },
          linkUrl: {
            en: 'https://www.pagepluscellular.com/ccpa/',
            es: 'https://www.pagepluscellular.com/ccpa/',
          },
        },
      },
    },
    recombee: {
      dev: {
        name: 'devrefactory-pageplus-dev',
        publicToken: 'UBZR6WXpKxfpCpmPBOaKVcHRQE50Uvz2YwZXS8DVK6c3gxwXoVEfNpro5FcAYuDA',
      },
      prod: {
        name: 'devrefactory-pageplus',
        publicToken: 'hVFiNRNNKZ1bXmntQulD1wA4wiVcebrIHIKkQV3rBKDzKyRcbL6ZC4JolhFYzCdX',
      },
    },
  },
  simplemobile: {
    loginLink: {
      en: 'https://www.simplemobile.com/myaccount/login/collectusername',
    },
    headerFooterUrl: 'https://assets-dev-kms-assets.s3.amazonaws.com',
    headerExternalLinkBase: '',
    contentCustomerId: 'simplemobile',
    customerName: 'Simple Mobile',
    gtmId: 'G-EPGCRR2GYP',
    gtmIdTracfone: 'GTM-3DZMTF',
    retailer: 'simplemobile',
    commonTopicsProductId: '24865',
    phoneSupport: '1-877-878-7908',
    contactSection: true,
    chatBotLink: 'https://website-production-bot-assets.s3.amazonaws.com/simplemobile/script.js',
    connectWithUsSection: true,
    popularFaqsPrefix: 'SM',
    popularFaqsCustomerId: 'simplemobile',
    isShowHiddenTutorial: false,
    specialHeaderAndFooter: false,
    footerConfig: {
      footerAdditionalLinks: {
        privacy_policy: {
          linkText: {
            en: 'Privacy Policy',
            es: 'Política de Privacidad',
          },
          linkUrl: {
            en: 'https://www.tracfonewirelessinc.com/en/Privacy%2BPolicy/',
            es: 'https://www.tracfonewirelessinc.com/es/Privacy%2BPolicy/',
          },
        },
        privacy_central: {
          linkText: {
            en: 'Privacy Central',
            es: 'Central de Privacidad',
          },
          linkUrl: {
            en: 'https://www.simplemobile.com/ccpa/home',
            es: 'https://www.simplemobile.com/ccpa/home',
          },
        },
        privacy_choices: {
          linkText: {
            en: 'Your Privacy Choices',
            es: 'Sus Opciones de Privacidad',
          },
          linkUrl: {
            en: 'https://www.simplemobile.com/ccpa/home?reqType=dns',
            es: 'https://www.simplemobile.com/ccpa/home?reqType=dns',
          },
          linkIcon: 'https://oag.ca.gov/sites/all/files/agweb/images/privacy/privacyoptions.svg',
        },
      },
    },
    recombee: {
      dev: {
        name: 'devrefactory-simplemobile-dev',
        publicToken: 'nPbgp9lsR4xzoe5pJHt8MUmccohtqwv7RRWX6ZMNtGZQRsZfI1XIviCeNlhxuY13',
      },
      prod: {
        name: 'devrefactory-simplemobile',
        publicToken: 'OKvxY0HnUnM7FnTPaK3HnutpXA7CT2VI4AOwMsv1P1HuK4FDfhppBDIXDJ1uepUU',
      },
    },
  },
  straighttalk: {
    loginLink: {
      en: 'https://www.straighttalk.com/#customerlogin',
      es: 'https://www.straighttalk.com/es/#customerlogin',
    },
    headerFooterUrl: 'https://assets-dev-kms-assets.s3.amazonaws.com/st',
    headerExternalLinkBase: 'https://www.straighttalk.com',
    contentCustomerId: 'straighttalk',
    customerName: 'Straighttalk',
    gtmId: 'G-NYE3JFZBKC',
    gtmIdTracfone: 'GTM-WRH7BJ',
    retailer: 'straighttalk',
    specialHeaderAndFooter: true,
    commonTopicsProductId: '24866',
    phoneSupport: '1-877-430-CELL(2355)',
    headerConfig: {
      separatedLogo: true,
      headerFlex: true,
      languageIcon: true,
      solidLocationIcon: true,
      boldLink: true,
      rewardsUp: true,
      shopIconUp: true,
      notGoToRetailer: true,
      helmetIcon: true,
      mobileHeaderSliding: true,
      specialShopList: true,
    },
    footerConfig: {
      backgroundColor: 'footer',
      textColor: 'text.footer',
      signForm: true,
      availableNone: false,
      availableNoneEs: true,
      textSmall: true,
      shop: true,
      referConditions: true,
      referConditionsLocal: true,
      isEmailInputVisible: true,
      footerAdditionalLinks: {
        privacy_policy: {
          linkText: {
            en: 'Privacy Policy',
            es: 'Política de Privacidad',
          },
          linkUrl: {
            en: 'https://www.tracfonewirelessinc.com/en/Privacy%2BPolicy/',
            es: 'https://www.tracfonewirelessinc.com/es/Privacy%2BPolicy/',
          },
        },
        privacy_central: {
          linkText: {
            en: 'Privacy Central',
            es: 'Central de Privacidad',
          },
          linkUrl: {
            en: 'https://www.straighttalk.com/ccpa/home',
            es: 'https://www.straighttalk.com/ccpa/home',
          },
        },
        privacy_choices: {
          linkText: {
            en: 'Your Privacy Choices',
            es: 'Sus Opciones de Privacidad',
          },
          linkUrl: {
            en: 'https://www.straighttalk.com/ccpa/home?reqType=dns',
            es: 'https://www.straighttalk.com/ccpa/home?reqType=dns',
          },
          linkIcon: 'https://oag.ca.gov/sites/all/files/agweb/images/privacy/privacyoptions.svg',
        },
      },
    },
    contactSection: true,
    chatBotLink: 'https://website-production-bot-assets.s3.amazonaws.com/straighttalk/script.js',
    connectWithUsSection: true,
    popularFaqsPrefix: 'ST',
    popularFaqsCustomerId: 'straighttalk',
    shopNowDevices: [
      {
        name: 'iPhone 11',
        redirectUrl: 'https://www.straighttalk.com/all-phones/apple-iphone-11-64gb-prepaid',
      },
      {
        name: 'iPhone SE (2020)',
        redirectUrl: 'https://www.straighttalk.com/all-phones/apple-iphone-se-64gb-prepaid',
      },
      {
        name: 'iPhone 13 - 5G Capable',
        redirectUrl: 'https://www.straighttalk.com/all-phones/apple-iphone-13-128-gb',
      },
      {
        name: 'S536DL',
        redirectUrl: 'https://www.straighttalk.com/all-phones/samsung-galaxy-a53-5g-prepaid',
      },
      {
        name: 'iPhone 13 Pro Max - 5G Capable',
        redirectUrl: 'https://www.straighttalk.com/all-phones/iphone-13-pro-max-128-gb',
      },
      {
        name: 'iPhone 14 - 5G w/eSIM',
        redirectUrl: 'https://www.straighttalk.com/all-phones/apple-iphone-14-128gb-prepaid',
      },
      {
        name: 'S136DL',
        redirectUrl: 'https://www.straighttalk.com/all-phones/samsung-galaxy-a13-5g-prepaid',
      },
      {
        name: 'iPhone 13 Pro (with 5G)',
        redirectUrl: 'https://www.straighttalk.com/all-phones/apple-iphone-13-pro-128-gb',
      },
      {
        name: 'iPhone 14 Plus - 5G w/eSIM',
        redirectUrl: 'https://www.straighttalk.com/all-phones/apple-iphone-14-plus-128gb-prepaid',
      },
      {
        name: 'G990U1/G990U3',
        redirectUrl: 'https://www.straighttalk.com/all-phones/samsung-galaxy-s21-fe-5g-prepaid',
      },
    ],
    isShowHiddenTutorial: false,
    recombee: {
      dev: {
        name: 'devrefactory-stacademy-dev',
        publicToken: 'vHovjAh0Ob3yb0zgXnXGFbD1dexs8Gbey2x3o84LjeDEQHyMZGtzXF37rmgt9Dmi',
      },
      prod: {
        name: 'devrefactory-prod',
        publicToken: 'fH7rewu9C33J6lEjRalKMcljT9Y4WwbCTJFDSOOkr5FqTSe4bF8Q9v7pNRuQ0NcN',
      },
    },
  },
  tracfone: {
    loginLink: {
      en: 'https://www.tracfone.com/login',
      es: 'https://www.tracfone.com/es/login',
    },
    headerFooterUrl: 'https://assets-dev-kms-assets.s3.amazonaws.com/tf',
    headerExternalLinkBase: 'https://www.tracfone.com',
    contentCustomerId: 'tracfone',
    customerName: 'Tracfone',
    gtmId: 'G-WEPT9JS0BE',
    gtmIdTracfone: 'GTM-KH46TX',
    retailer: 'tracfone',
    specialHeaderAndFooter: true,
    commonTopicsProductId: '24892',
    phoneSupport: '1-800-867-7183',
    headerConfig: {
      separatedLogo: false,
      headerFlex: true,
      solidLocationIcon: true,
      boldLink: false,
      notGoToRetailer: true,
      mobileHeaderSliding: true,
      mobile: {
        navigationLinks: true,
        withoutIconShop: true,
        withoutHeading: true,
      },
      shopListLogo: true,
    },
    footerConfig: {
      backgroundColor: 'footer',
      textColor: 'text.footer',
      signForm: true,
      availableNone: true,
      availableNoneEs: true,
      textSmall: true,
      shop: true,
      referConditions: true,
      referConditionsLocal: false,
      isEmailInputVisible: true,
      footerAdditionalLinks: {
        privacy_policy: {
          linkText: {
            en: 'Privacy Policy',
            es: 'Política de Privacidad',
          },
          linkUrl: {
            en: 'https://www.tracfonewirelessinc.com/en/Privacy%2BPolicy/',
            es: 'https://www.tracfonewirelessinc.com/es/Privacy%2BPolicy/',
          },
        },
        privacy_central: {
          linkText: {
            en: 'Privacy Central',
            es: 'Central de Privacidad',
          },
          linkUrl: {
            en: 'https://www.tracfone.com/ccpa/home',
            es: 'https://www.tracfone.com/ccpa/home',
          },
        },
        privacy_choices: {
          linkText: {
            en: 'Your Privacy Choices',
            es: 'Sus Opciones de Privacidad',
          },
          linkUrl: {
            en: 'https://www.tracfone.com/ccpa/home?reqType=dns',
            es: 'https://www.tracfone.com/ccpa/home?reqType=dns',
          },
          linkIcon: 'https://oag.ca.gov/sites/all/files/agweb/images/privacy/privacyoptions.svg',
        },
      },
    },
    contactSection: true,
    connectWithUsSection: true,
    chatBotLink: 'https://website-production-bot-assets.s3.amazonaws.com/tracfone/script.js',
    popularFaqsPrefix: 'TF',
    popularFaqsCustomerId: 'tracfone',
    isShowHiddenTutorial: false,
    recombee: {
      dev: {
        name: 'devrefactory-tracfone-dev',
        publicToken: '5TVKRPk5SYvubbPyC8iUaCaFGfg8xiYu8aVmzOJxOdVdCqmpxlH4ZHqiGHrDe8jL',
      },
      prod: {
        name: 'devrefactory-tracfone',
        publicToken: 'MTvwvDt74OydDPXtYgnHI8sDTN6QQJUQcMVxLLxaE3fgJBd0sPdC1bfcHW4r2nKi',
      },
    },
  },
  safelink: {
    loginLink: {
      en: 'https://www.safelinkwireless.com/Enrollment/Safelink/en/Web/www/default/index.html#!/login',
    },
    headerFooterUrl: 'https://assets-dev-kms-assets.s3.amazonaws.com',
    headerExternalLinkBase: '',
    contentCustomerId: 'safelink',
    customerName: 'Safelink',
    gtmId: 'G-1VNXJMTG9X',
    gtmIdTracfone: 'GTM-KWP8HW',
    retailer: 'safelink',
    commonTopicsProductId: '24934',
    phoneSupport: '1-800-378-1684',
    contactSection: true,
    connectWithUsSection: false,
    chatBotLink:'https://website-production-bot-assets.s3.amazonaws.com/safelink/script.js',
    popularFaqsPrefix: 'SL',
    popularFaqsCustomerId: 'safelink',
    isShowHiddenTutorial: false,
    specialHeaderAndFooter: false,
    footerConfig: {
      footerAdditionalLinks: {
        privacy_policy: {
          linkText: {
            en: 'Privacy Policy',
            es: 'Política de Privacidad',
          },
          linkUrl: {
            en: 'https://www.tracfonewirelessinc.com/en/Privacy%2BPolicy/',
            es: 'https://www.tracfonewirelessinc.com/es/Privacy%2BPolicy/',
          },
        },
        privacy_central: {
          linkText: {
            en: 'Privacy Central',
            es: 'Central de Privacidad',
          },
          linkUrl: {
            en: 'https://www.Safelinkwireless.com/Enrollment/Safelink/en/Web/www/default/index.html#!/myAccount/privacy',
            es: 'https://www.Safelinkwireless.com/Enrollment/Safelink/en/Web/www/default/index.html#!/myAccount/privacy',
          },
        },
      },
    },
    recombee: {
      dev: {
        name: 'devrefactory-safelink-dev',
        publicToken: 'PUnWx1ttBOPrWXY5AEjfCQRKK81ABkduIwguinRkChtzBcEdatDLPZ4Q4WhyTqhE',
      },
      prod: {
        name: 'devrefactory-safelink',
        publicToken: 'LLcy1M2KYYeATLOYDhtqNdTctxQliufvdH4KKhffM1Zn2Lt8apMfQbqFrY4AO9Dm',
      },
    },
  },
  tfunbranded: {
    loginLink: {
      en: 'https://www.totalbyverizon.com/login',
      es: 'https://www.totalbyverizon.com/es/login',
    },
    headerFooterUrl: 'https://assets-dev-kms-assets.s3.amazonaws.com/tbv',
    headerExternalLinkBase: 'https://www.totalbyverizon.com',
    contentCustomerId: 'tfunbranded',
    customerName: 'Tfunbranded',
    gtmId: 'G-9R299NZ0J7',
    retailer: 'tfunbranded',
    specialHeaderAndFooter: false,
    commonTopicsProductId: '25209',
    phoneSupport: '1-866-663-3633',
    footerConfig: {
      support: false,
      footerAdditionalLinks: {
        privacy_policy: {
          linkText: {
            en: 'Privacy Policy',
            es: 'Política de Privacidad',
          },
          linkUrl: {
            en: 'https://www.tracfonewirelessinc.com/en/Privacy%2BPolicy/',
            es: 'https://www.tracfonewirelessinc.com/es/Privacy%2BPolicy/',
          },
        },
        privacy_central: {
          linkText: {
            en: 'Privacy Central',
            es: 'Central de Privacidad',
          },
          linkUrl: {
            en: 'https://www.totalbyverizon.com/ccpa/home',
            es: 'https://www.totalbyverizon.com/ccpa/home',
          },
        },
        privacy_choices: {
          linkText: {
            en: 'Your Privacy Choices',
            es: 'Sus Opciones de Privacidad',
          },
          linkUrl: {
            en: 'https://www.totalbyverizon.com/ccpa/home?reqType=dns',
            es: 'https://www.totalbyverizon.com/ccpa/home?reqType=dns',
          },
          linkIcon: 'https://oag.ca.gov/sites/all/files/agweb/images/privacy/privacyoptions.svg',
        },
      },
    },
    contactSection: true,
    connectWithUsSection: true,
    popularFaqsPrefix: '',
    popularFaqsCustomerId: '',
    isShowHiddenTutorial: true,
    recombee: {
      dev: {
        name: 'devrefactory-tfunbranded-dev',
        publicToken: 'COWleqXy9rHq2bFUXuNTVoCLNupaEqt4Z2mSzB0rE8EU6qT54ePekCG3oFX9DusF',
      },
      prod: {
        name: 'devrefactory-tfunbranded',
        publicToken: 'xf5yemiM6NZ2U5Zi2l5BmaUOk4iyVAgfGwCf9eWAKoMci33qaRucUKxDPtQk9Xz9',
      },
    },
  },
} as const

export const customersObj: Record<Customer, CustomerConfig> =
  Object.entries(brands).reduce((acc, [brand, value]) => {
    // For each brand create a PREPROD by appending `-preprod` to
    // contentCustomerId
    acc[brand as Customer] = value
    acc[`${brand}-preprod` as Customer] = {
      ...value,
      contentCustomerId: `${value.contentCustomerId}-preprod`,
    }
    return acc
  }, {} as Record<Customer, CustomerConfig>)
