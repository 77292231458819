import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Link: ComponentStyleConfig = {
  baseStyle: {
    _focus: {
      boxShadow: 'none',
    },
  },
  variants: {
    showAll: {
      mt: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 700,
      fontSize: 'md',
      rounded: 50,
      border: '1px solid',
      _focus: { boxShadow: '0 0 0 3px rgba(21, 156, 228, 0.4)' },
      width: 200,
      height: 50,
    },
    navLink: {
      w: 'max-content',
      fontWeight: 'bold',
      cursor: 'pointer',
      outline: '1px solid transparent',
      outlineOffset: '0',
      border: '2px solid transparent',
      borderRadius: '5px',
      _hover: { color: 'none', textDecoration: 'underline' },
      _focusVisible: {
        outlineColor: '#FFFFFF',
        borderColor: '#000000',
      },
    },
    mobileNavLink: {
      fontWeight: 'bold',
      cursor: 'pointer',
      borderBottom: '1px',
      borderColor: 'gray.200',
      pl: 5,
    },
  },
}
