export const brands = [
  'tbv',
  'wfm',
  'gosmart',
  'net10',
  'pageplus',
  'simplemobile',
  'straighttalk',
  'tracfone',
  'safelink',
  'tfunbranded',
] as const

export type Brand = typeof brands[number]

export const supportedCustomers = [
  'tbv',
  'wfm',
  'gosmart',
  'net10',
  'pageplus',
  'simplemobile',
  'straighttalk',
  'tracfone',
  'safelink',
  'tfunbranded',

  'tbv-preprod',
  'wfm-preprod',
  'gosmart-preprod',
  'net10-preprod',
  'pageplus-preprod',
  'simplemobile-preprod',
  'straighttalk-preprod',
  'tracfone-preprod',
  'safelink-preprod',
  'tfunbranded-preprod',
] as const

export type Customer = typeof supportedCustomers[number]

type FooterAdditionalLink = { 
  linkText: {
    en: string
    es: string
  }
  linkUrl: {
    en: string
    es: string
  }
  linkIcon?: string
}

export type CustomerConfig = {
  loginLink: {
    en: string
    es?: string
  }
  headerFooterUrl: string
  headerExternalLinkBase: string
  contentCustomerId: string
  customerName: string
  gtmId: string
  gtmIdTracfone?: string
  retailer: Customer
  specialHeaderAndFooter?: boolean
  commonTopicsProductId: string
  phoneSupport: string
  headerConfig?: {
    separatedLogo?: boolean
    headerFlex?: boolean
    languageIcon?: boolean
    solidLocationIcon?: boolean
    boldLink?: boolean
    rewardsUp?: boolean
    shopIconUp?: boolean
    notGoToRetailer?: boolean
    helmetIcon?: boolean
    mobileHeaderSliding?: boolean
    specialShopList?: boolean
    mobile?: {
      navigationLinks: boolean
      withoutIconShop: boolean
      withoutHeading: boolean
    }
    shopListLogo?: boolean
  }
  footerConfig: {
    support?: boolean
    backgroundColor?: string
    textColor?: string
    signForm?: boolean
    availableNone?: boolean
    availableNoneEs?: boolean
    textSmall?: boolean
    shop?: boolean
    referConditions?: boolean
    referConditionsLocal?: boolean
    isEmailInputVisible?: boolean
    footerAdditionalLinks: {
      privacy_policy: FooterAdditionalLink
      privacy_central: FooterAdditionalLink
      privacy_choices?: FooterAdditionalLink
    }
  }
  contactSection: boolean
  chatBotLink?: string
  connectWithUsSection: boolean
  popularFaqsPrefix: string
  popularFaqsCustomerId: string
  recombee: {
    dev: {
      name: string
      publicToken: string
    }
    prod: {
      name: string
      publicToken: string
    }
  }
  shopNowDevices?: readonly { name: string, redirectUrl: string }[]
  isShowHiddenTutorial: boolean
}

export const isCustomer = (a: any): a is Customer =>
  supportedCustomers.includes(a)
