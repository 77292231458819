import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Container: ComponentStyleConfig = {
  baseStyle: {
    maxW: 'none',
  },
  sizes: {},
  variants: {
    header: {
      px: ['2%', '2%', '2%', 0, 0],
      bg: 'header',
      borderBottom: '1px solid',
      borderColor: 'gray.200',
      h: [14, 14, 14, 'auto'],
      minH: 68,
      maxH: 28,
      w: 'full',
      display: 'block',
      p: 0,
      position: 'relative',
    },
    header_flex: {
      display: 'flex',
      flexDir: 'row',
      h: 'auto',
      pt: [2, 2, 2, 2, 5],
      px: ['auto', 'auto', 'auto', 2, 30],
      bg: 'header',
      borderBottom: '1px solid',
      borderColor: 'gray.200',
      w: 'full',
    },
    mobileHeader: {
      color: 'black',
      px: [0, 0, 3],
      display: 'flex',
      flexDirection: 'column',
      borderTop: ['1px', '1px', 'none'],
      borderColor: ['gray.200', 'gray.200'],
    },
    shopList: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexDirection: ['row', 'row', 'column'],
      py: 4,
      borderTop: ['1px', '1px', 'none'],
      borderColor: ['gray.200', 'gray.200'],
      _last: {
        borderBottom: ['1px solid', '1px solid', 'none'],
        borderColor: ['gray.200', 'gray.200'],
      },
    },
    navText: {
      fontWeight: 'bold',
      cursor: 'pointer',
      _hover: { color: 'gray.600' },
      ml: 6,
      fontSize: 'sm',
      display: 'flex',
      p: 0,
    },
    KnowledgeBoxContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: ['column', 'column', 'column', 'row'],
      gap: 10,
      mt: [0, 0, 10],
      maxW: 1800,
      w: 'full',
      py: [0, 0, 10],
      px: 22,
      flexWrap: ['wrap', 'wrap', 'wrap', 'nowrap'],
      color: 'badge.text',
    },
    knowledgeBox: {
      bg: 'badge.bg',
      p: [10, 5, 5, 5, 10],
      maxW: 390,
      h: 'auto',
      cursor: 'pointer',
    },
    tutorialBox: {
      px: 20,
      py: 10,
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
      borderColor: 'gray.200',
      flexWrap: 'wrap',
      pb: 10,
      gap: 10,
    },
    contact: {
      pt: [35, 35, 65],
      pb: [35, 35, 65],
      mt: 10,
    },
    contactItem: {
      display: ['none', 'none', 'flex', 'flex', 'flex'],
      justifyContent: 'center',
      maxW: 800,
      flexWrap: 'wrap',
      gap: 4,
    },
    footer: {
      pt: 16,
      pb: 16,
    },
    footer_email_notification: {
      w: '80',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      bgColor: '#fff4f4',
      color: '#d58a8a',
      fontSize: 13,
      zIndex: 9,
      p: 0.5,
      pl: 2,
      top: 8,
      left: 3,
      border: '1px solid',
      borderRadius: 3,
      position: 'absolute',
    },
    faqBox: {
      alignSelf: 'center',
      w: 610,
      p: 0,
    },
    phonesNav: {
      mt: 16,
      px: [2, 20],
    },
    phonesInfoIMEI: {
      display: 'flex',
      justifyContent: 'center',
      alignItem: 'center',
      gap: 8,
      flexWrap: 'wrap',
      mb: 4,
    },
    phonesNavigation: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 6,
      mb: 4,
    },
    brands: {
      maxW: 1800,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: [4, 10],
      mt: 10,
      p: [0, 1],
    },
    brand: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      w: [40, 44, 44, 72, 72],
      h: 24,
      bg: 'gray.100',
      gap: [3, 3, 3, 6],
      cursor: 'pointer',
      m: 0,
    },
    selectedBrandBox: {
      px: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 4,
    },
    selectedBrandItem: {
      border: '1px solid',
      borderColor: 'primary',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 3,
      px: 2,
      py: 2,
      minH: 9,
    },
    selectedBrandIcon: {
      h: 4,
      w: 4,
      display: 'flex',
      justifyContent: 'center',
      p: 0,
    },
    models: {
      maxW: 1800,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'start',
      gap: 8,
      flexWrap: 'wrap',
      my: 8,
      px: [4, 10, 20, 32],
    },
    navSelectedItem: {
      pl: 0,
      display: 'flex',
      justifyContent: 'flex-start',
      pt: 8,
    },
    navSerchBlock: {
      px: 0,
      borderBottom: '2px solid black',
      pt: 5,
      pb: 8,
    },
    contactBox: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: 8,
      p: [2, 4, 8],
      bg: 'gray.100',
      my: 8,
    },
    footerBlock: {
      pt: [8, 8, 8, 28],
      px: 0,
    },
    touchItem: {
      position: 'absolute',
      bg: 'black.300',
      borderRadius: '50%',
      border: '2px solid white',
      zIndex: 3,
    },
    topic: {
      cursor: 'pointer',
      w: 44,
      py: 4,
      _hover: { border: '1px solid', borderColor: 'black' },
      border: '1px solid #ffffff00',
    },
    answers: {
      a: {
        color: 'blue',
        textDecoration: 'underline',
        cursor: 'pointer',
        _hover: {
          opacity: '0.7',
        },
      },
      'ul, ol, dl': {
        pl: 10,
      },
    },
    activeTopic: {
      cursor: 'pointer',
      w: 44,
      py: 4,
      bg: 'categoryFaq.bg',
      border: '1px solid',
      borderColor: 'categoryFaq.border',
    },
    mainHeaderBlock: {
      display: ['none', 'none', 'none', 'flex'],
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '70vw',
      p: [0, 0, 0, 1, 4],
      margin: 0,
    },
    headerBlock: {
      display: ['none', 'none', 'none', 'flex'],
      justifyContent: 'center',
      alignItems: 'center',
      p: [0, 0, 0, 4, 4],
      gap: 4,
      width: 'auto',
      margin: 0,
    },
    shopListContainer: {
      justifyContent: 'flex-start',
      borderColor: 'gray.200',
      border: '1px solid',
      alignItems: 'center',
      bgColor: 'white',
      display: 'flex',
      width: ['full', 'full', 222],
      height: 66,
      margin: 0,
      px: 6,
      py: 4,
      mt: 3,
    },
    mobileBurgerMenu: {
      position: 'absolute',
      top: [20, 20, 36],
      right: 0,
      display: ['block', 'block', 'block', 'none'],
      zIndex: 7,
    },
    faqBullets: {
      li: {
        marginLeft: 8,
      },
    },
    helpful: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      mt: 14,
    },
    phoneModel: {
      fontSize: 14,
      fontWeight: 700,
      textAlign: 'center',
    },
    searchInAnswer: {
      p: 0,
      pl: 3,
      color: 'gray.600',
      ul: {
        pl: 10,
      },
    },
    totalSupportContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      p: 0,
    },
    searchHomeContainer: {
      display: 'flex',
      maxW: 1800,
      alignItems: 'center',
      width: '100%',
      my: 1,
      mx: 0,
      p: 0,
      justifyContent: 'center',
      flexDirection: 'column',
    },
    searchHomeContentContainer: {
      mt: 5,
      display: 'flex',
      flexDirection: ['column', 'column', 'column', 'row', 'row'],
      alignItems: ['center', 'center', 'center', 'flex-start'],
      justifyContent: 'space-around',
      width: '100%',
      '.ais-Hits-list': {
        'list-style-type': 'none',
      },
      '.ais-Panel-header': {
        'font-weight': '700',
        'margin-bottom': '1rem',
        'margin-top': '2.5rem',
      },
      '.ais-RefinementList-list': {
        'list-style-type': 'none',
      },
      '.ais-RefinementList-checkbox': {
        'margin-right': '10px',
      },
      '.ais-RefinementList-showMore--disabled': {
        display: 'none',
      },
    },
    searchHomeFilterContainer: {
      display: 'flex',
      justifyContent: ['center', 'center', 'space-around', 'flex-start'],
      flexDirection: ['column', 'row', 'row', 'column', 'column'],
      alignItems: 'flex-start',
      gap: 7,
      maxW: ['xs', 'xl', 'xl', '2xs'],
      w: '100%',
      mb: 10,
      px: 10,
    },
    searchHomeHitContainer: {
      mt: 5,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'flex-start',
      align: 'center',
      maxW: '7xl',
      gap: 5,
      mx: [0, 5],
    },
    searchGroupHits: {
      display: 'flex',
      flexDir: 'column',
      alignItems: 'center',
    },
    searchHitsList: {
      p: 0,
      m: 0,
      '.ais-Hits-list': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: ['center', 'center', 'center', 'center', 'flex-start'],
        gap: 5,
        mb: 10,
      },
    },
    searchSelectedModel: {
      w: '100%',
      m: 0,
      mt: 3,
      px: [20, 20, 20, 7, 7],
      alignItems: 'center',
      flexDirection: ['column', 'row', 'row'],
      justifyContent: ['space-around', 'space-around', 'flex-start'],
    },
    searchFilterContainer: {
      display: 'flex',
      '.chakra-radio__control': {
        border: '1px solid',
        _focus: {
          boxShadow: 'none',
        },
      },
      '.chakra-radio__control[data-checked]': {
        color: 'bot.bg',
        borderColor: 'black',
      },
      '.chakra-radio__control[data-checked]::before': {
        w: '70%',
        h: '70%',
        borderRadius: '70%',
      },
      '.chakra-radio__label': {
        cursor: 'auto',
      },
    },
    searchHit: {
      display: 'flex',
      mb: 2,
      bgColor: 'contact.bg',
      width: [56, 'xs'],
      color: 'contact.text',
      h: 32,
      alignItems: 'center',
    },
    searchPagination: {
      '.ais-Pagination-list': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '.ais-Pagination-item--selected': {
        fontWeight: 'bold',
        color: 'black !important',
      },
      '.ais-Pagination-item': {
        _hover: {
          color: 'black',
        },
        m: [3, 3, 3, 5],
        color: 'gray.400',
        display: 'inline-block',
      },
      'span': {
        fontSize: '2xl',
      },
    },
  },
}
