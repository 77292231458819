import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Button: ComponentStyleConfig = {
  variants: {
    contact: {
      rounded: 50,
      mt: 8,
      border: '1px solid',
      _focus: { boxShadow: '0 0 0 3px rgba(21, 156, 228, 0.4)' },
      width: 200,
      height: 50,
    },
    knowledgeBox: {
      bg: 'badge.bg',
      p: [10, 5, 5, 5, 10],
      maxW: 390,
      h: 'auto',
      cursor: 'pointer',
    },
    focusedButton: {
      outline: '1px solid transparent',
      outlineOffset: '0',
      border: '2px solid transparent',
      borderRadius: '5px',
      _focusVisible: {
        outlineColor: '#FFFFFF',
        borderColor: '#000000',
      },
    },
    headerAccordionFlyout: {
      color: 'white',
      bgColor: '#c74100',
      w: 56,
      fontSize: 'xs',
      h: 12,
      mt: 16,
      p: 15,
      outline: '1px solid transparent',
      outlineOffset: '0',
      border: '2px solid transparent',
      borderRadius: '5px',
      _hover: {
        color: 'white',
      },
      _focusVisible: {
        outlineColor: '#FFFFFF',
        borderColor: '#000000',
      },
    },
    phoneButton: {
      rounded: 50,
      border: '1px solid',
      _focus: {
        boxShadow: 'none',
        opacity: 0.7,
      },
      _hover: {
        opacity: 0.7,
        borderColor: 'gray.500',
      },
      width: 'full',
      height: 12,
      maxW: 190,
    },
    helpful: {
      rounded: 50,
      border: '1px solid black',
      _focus: {
        opacity: 0.7,
        borderColor: 'gray.500',
        boxShadow: 'none',
      },
      _hover: {
        opacity: 0.7,
        borderColor: 'gray.500',
      },
      width: 115,
      height: 45,
      mr: 3,
    },
    helpfulIcon: {
      _hover: {
        backgroundColor: 'none',
      },
      outline: 'none',
      backgroundColor: 'none',
      p: 0,
      minW: 7,
    },
    globalFaq: {
      fontSize: 'md',
      whiteSpace: 'wrap',
    },
    topics: {
      bg: 'gray.100',
      borderRadius: 0,
      fontSize: 'xs',
      _focus: {
        opacity: 0.7,
        boxShadow: 'none',
      },
    },
    zipModalButton: {
      width: 210,
      borderRadius: 50,
      h: 10,
      bgColor: 'black',
      textColor: 'white',
      fontSize: 'lg',
      mr: 3,
    },
    inputSearchButton: {
      p: 0,
      borderRadius: 0,
      bg: 'search',
      _focus: {
        opacity: 0.7,
        boxShadow: 'none',
      },
      _hover: {
        opacity: 0.7,
      },
      _active: {
        opacity: 0.7,
      },
    },
    showMore: {
      w: 'fit-content',
      borderRadius: 60,
      fontWeight: 'bold',
      px: 10,
      py: 4,
      border: '1px solid black',
      bgColor: 'transparent',
      _focus: {
        boxShadow: 'none',
      },
    },
    searchItem: {
      _hover: { color: 'primary' },
      _focus: { color: 'primary' },
      cursor: 'pointer',
      h: 'auto',
      minW: 'none',
      minH: 'none',
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 'normal',
      my: 2,
      p: 0,
    },
  },
}
